export const initSelect = function (singleSelect) {
  const selectElements = {
    nativeSelect: null,
    customSelect: null,
    customTrigger: null,
    customOptionsWrapper: null,
    customOptions: [],
    customOptionsCount: 0,
    optionChecked: '',
    optionHoveredIndex: -1
  }

  const observerConfig = { attributes: true, childList: true, subtree: true }

  const observerCallback = function (mutationsList) {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList' || mutation.type === 'attributes') {
        if (selectElements.customOptionsWrapper) selectElements.customOptionsWrapper.innerHTML = ''
        addOptionsToWrapper(selectElements.customOptionsWrapper, singleSelect)
      }
    }
  }

  function init() {
    if (!singleSelect) return

    singleSelect.classList.add('native-select')

    const selectParent = singleSelect.parentNode
    const selectWrapper = document.createElement('DIV')

    selectWrapper.classList.add('select-wrapper')

    if (singleSelect.classList.contains('white')) selectWrapper.classList.add('select-wrapper--white')

    selectWrapper.appendChild(singleSelect)
    selectElements.nativeSelect = singleSelect

    //Custom select wrapper
    const customWrapper = document.createElement('DIV')
    customWrapper.classList.add('custom-select')
    customWrapper.setAttribute('aria-hidden', 'true')

    //Trigger
    const customTrigger = document.createElement('DIV')
    customTrigger.classList.add('custom-select__trigger', 'custom-select__trigger--init')
    customTrigger.innerHTML = singleSelect.options[0]?.innerHTML ?? ''
    customWrapper.appendChild(customTrigger)
    selectElements.customTrigger = customTrigger

    //Options wrapper
    const customOptionsWrapper = document.createElement('DIV')
    customOptionsWrapper.classList.add('custom-select__options')
    customWrapper.appendChild(customOptionsWrapper)
    selectElements.customOptionsWrapper = customOptionsWrapper

    //Options
    addOptionsToWrapper(customOptionsWrapper, singleSelect)

    //Append all items
    selectWrapper.appendChild(customWrapper)
    selectParent.appendChild(selectWrapper)
    selectElements.customSelect = selectWrapper

    addEventHandlersToSelect()

    const observer = new MutationObserver(observerCallback)
    observer.observe(singleSelect, observerConfig)

    // }
  }

  const addOptionsToWrapper = function (wrapper, singleSelect) {
    for (var i = 0; i < singleSelect.length; i++) {
      if (!singleSelect.options[i].disabled) {
        //TODO: Add support for disabled options

        const singleOption = document.createElement('DIV')

        singleOption.classList.add('custom-select__option')
        singleOption.innerHTML = singleSelect.options[i].innerText
        singleOption.setAttribute('data-value', singleSelect.options[i].attributes.value.value)

        if (singleSelect.options[i].selected) {
          singleOption.classList.add('isActive')
          selectElements.customTrigger.textContent = singleSelect.options[i].innerText

          if (document.querySelector('.my-pages')) {
            selectElements.customTrigger.setAttribute('data-visual', singleSelect.options[i].attributes.value.value)
          }

          if (selectElements.customTrigger.classList.contains('custom-select__trigger--init')) selectElements.customTrigger.classList.remove('custom-select__trigger--init')
        }

        wrapper.appendChild(singleOption)
        selectElements.customOptions.push(singleOption)
        selectElements.customOptionsCount = selectElements.customOptionsCount++
      }
    }
  }

  const addEventHandlersToSelect = function () {
    // Toggle custom select visibility when clicking the box
    selectElements.customTrigger.addEventListener('click', () => {
      const isClosed = !selectElements.customSelect.classList.contains('isActive')

      if (isClosed) openSelectCustom()
      else closeSelectCustom()
    })

    // Update selectCustom value when selectNative is changed.
    selectElements.nativeSelect.addEventListener('change', (e) => {
      const value = e.target.value
      if (value.length > 0) {
        const elRespectiveCustomOption = selectElements.customOptionsWrapper.querySelectorAll(`[data-value="${value}"]`)[0]
        updateCustomSelectChecked(value, elRespectiveCustomOption.textContent)
      }
    })

    // Update selectCustom value when an option is clicked or hovered
    selectElements.customOptionsWrapper.addEventListener('click', (e) => {
      const el = e.target
      const value = el.getAttribute('data-value')

      if (value) {
        // Sync native select to have the same value
        selectElements.nativeSelect.value = value

        //Trigger native select change
        var event = new Event('change')
        selectElements.nativeSelect.dispatchEvent(event)

        updateCustomSelectChecked(value, el.textContent)
      }
      closeSelectCustom()
    })

    // selectElements.customOptions.forEach(function(elOption, index) {
    //   elOption.addEventListener('click', e => {
    //     const value = e.target.getAttribute('data-value')

    //     // Sync native select to have the same value
    //     selectElements.nativeSelect.value = value

    //     //Trigger native select change
    //     var event = new Event('change')
    //     selectElements.nativeSelect.dispatchEvent(event)

    //     updateCustomSelectChecked(value, e.target.textContent)
    //     closeSelectCustom()
    //   })

    //   elOption.addEventListener('mouseenter', () => {
    //     updateCustomSelectHovered(index)
    //   })

    //   // TODO: Toggle these event listeners based on selectCustom visibility
    // })
  }

  function openSelectCustom() {
    selectElements.customSelect.classList.add('isActive')
    // Remove aria-hidden in case this was opened by a user
    // who uses AT (e.g. Screen Reader) and a mouse at the same time.
    selectElements.customSelect.setAttribute('aria-hidden', false)

    if (selectElements.optionChecked) {
      const optionCheckedIndex = selectElements.customOptions.findIndex((el) => el.getAttribute('data-value') === selectElements.optionChecked)
      updateCustomSelectHovered(optionCheckedIndex)
    }

    // Add related event listeners
    document.addEventListener('click', watchClickOutside)
    document.addEventListener('keydown', supportKeyboardNavigation)
  }

  function closeSelectCustom() {
    selectElements.customSelect.classList.remove('isActive')
    selectElements.customSelect.setAttribute('aria-hidden', true)
    updateCustomSelectHovered(-1)

    // Remove related event listeners
    document.removeEventListener('click', watchClickOutside)
    document.removeEventListener('keydown', supportKeyboardNavigation)
  }

  function updateCustomSelectHovered(newIndex) {
    const prevOption = selectElements.customOptionsWrapper.children[selectElements.optionHoveredIndex]
    const option = selectElements.customOptionsWrapper.children[newIndex]

    if (prevOption) prevOption.classList.remove('isHover')

    if (option) option.classList.add('isHover')

    selectElements.optionHoveredIndex = newIndex
  }

  function updateCustomSelectChecked(value, text) {
    //const prevValue = selectElements.optionChecked;
    const elPrevOption = selectElements.customOptionsWrapper.querySelector(`.isActive`)
    const elOption = selectElements.customOptionsWrapper.querySelector(`[data-value="${value}"`)

    if (elPrevOption) elPrevOption.classList.remove('isActive')

    if (elOption) elOption.classList.add('isActive')

    if (document.querySelector('.my-pages')) {
      selectElements.customTrigger.setAttribute('data-visual', value)
    }

    selectElements.customTrigger.textContent = text
    selectElements.optionChecked = value

    if (selectElements.customTrigger.classList.contains('custom-select__trigger--init')) selectElements.customTrigger.classList.remove('custom-select__trigger--init')
  }

  function watchClickOutside() {
    const didClickedOutside = !selectElements.customSelect.contains(event.target)

    if (didClickedOutside) closeSelectCustom()
  }

  function supportKeyboardNavigation(e) {
    // press down -> go next
    if (e.keyCode === 40 && selectElements.optionHoveredIndex < selectElements.customOptionsCount - 1) {
      //let index = selectElements.optionHoveredIndex;
      e.preventDefault() // prevent page scrolling
      updateCustomSelectHovered(selectElements.optionHoveredIndex + 1)
    }

    // press up -> go previous
    if (e.keyCode === 38 && selectElements.optionHoveredIndex > 0) {
      e.preventDefault() // prevent page scrolling
      updateCustomSelectHovered(selectElements.optionHoveredIndex - 1)
    }

    // press Enter or space -> select the option
    if (e.keyCode === 13 || event.keyCode === 32) {
      e.preventDefault()

      const option = selectElements.customOptionsWrapper.children[selectElements.optionHoveredIndex]
      const value = option && option.getAttribute('data-value')

      if (value) {
        selectElements.nativeSelect.value = value
        updateCustomSelectChecked(value, option.textContent)
      }

      closeSelectCustom()
    }

    // press ESC -> close selectCustom
    if (event.keyCode === 27) closeSelectCustom()
  }

  init()
}
