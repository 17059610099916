import { initSelect } from './select'

function removeFile(index, fileUploadNode){
  let attachments = fileUploadNode.files;
  let fileBuffer = new DataTransfer();

  for (let i = 0; i < attachments.length; i++) {
      // Exclude file in specified index
      if (index !== i)
          fileBuffer.items.add(attachments[i]);
  }
  
  // Assign buffer to file input
  fileUploadNode.files = fileBuffer.files;
}

export const initForms = function ($) {
  if ($('form.EPiServerForms').length > 0) {
    $('form.EPiServerForms .Form__Element.ValidationFail[data-f-type="textbox"] >label + *:not(.wrapper_el)').wrap('<div class="wrapper_el"></div>').after('<span></span>');
    $('form.EPiServerForms input[type="reset"]').wrap('<div class="wrapper_el_reset"></div>').after('<span></span>');


    if ($('form.EPiServerForms .Form__Element.FormFileUpload').length > 0) {
      window.filesToUploadSize = 0;

      $('form.EPiServerForms .Form__Element.FormFileUpload').each(function() {
          const fileUpload = $(this).find('.FormFileUpload__Input')
          const wrapper = $('<div />', { class: 'wrapper_el_button' })

          fileUpload.appendTo(wrapper)
          $('<label />', { text: 'Välj filer',  class: 'button button-green', for: $(fileUpload).attr('id'), role: 'button' }).appendTo(wrapper)
          $('<ul />', { class: 'fileList' }).appendTo(wrapper)

          $(this).append(wrapper)
      });

      $('form.EPiServerForms .Form__Element.FormFileUpload').change(function(e) {
          window.filesToUploadSize = 0;
          
          for (let file of e.target.files) {
            window.filesToUploadSize = window.filesToUploadSize + file.size;
          }
          
          const output = [];
          for (let i = 0; i < e.target.files.length; i++) {
              const file = e.target.files[i]
              var removeLink = `<a class="removeFile" href="#" data-fileid="${i}"><span style="word-break:break-word" class="break-words">${file.name}</span></a>`;
              output.push('<li>', removeLink, '</li>');
          }
          $(this).find('.fileList').empty().append(output.join(''));
      });

      $('form.EPiServerForms .Form__Element.FormFileUpload').on('click', '.removeFile', function(e) {
        e.preventDefault();
        let filesToUpload = $(this).closest('.Form__Element.FormFileUpload').find('input[type="file"]').get(0).files;
        const fileName = $(this).parent().children("a").text();

        for (let i = 0; i < filesToUpload.length; i++) {
          if (filesToUpload[i].name == fileName) { 
            window.filesToUploadSize = window.filesToUploadSize - filesToUpload[i].size;           
            removeFile(i, $(this).closest('.Form__Element.FormFileUpload').find('input[type="file"]').get(0));
          }
        }
        $(this).parent().remove()
      });
    }

    if ($('form.EPiServerForms .Form__Element.FormSelection').length > 0) {
      $('form.EPiServerForms .Form__Element.FormSelection select:not([multiple])').each(function() {
        initSelect(this);
      });
    }

    $('form.EPiServerForms input[type="checkbox"]').after('<span></span>');
    $('form.EPiServerForms input[type="radio"]').after('<span></span>');

    if ($("form [required][value!='PreviousStep'][value!='NextStep'][name!='searchInput']").length > 0 ) {
      const text = $('html').attr('lang') === 'en' ? 'Required' : 'Obligatoriskt'
      
    $("button:not(.FormCaptcha__Refresh)[name*='submit'][value!='PreviousStep'][value!='NextStep']")
      .before('<label for="name" class="RequiredFields">* ' + text + '</label>');
      
    $('form.EPiServerForms .Form__Element.FormParagraphText').addClass('wysiwyg').addClass('wysiwyg-full');
    
    /* 
    $('form.EPiServerForms .Form__MainBody >section').before('<label for="name" class="RequiredFields">* ' + text + '</label>'); 
    */
    }
  }
  

  if ($('select.select-element:not([multiple])').length > 0) {
    $('select.select-element:not([multiple])').each(function() {
      initSelect(this)
    })
  } else {
    $('select[multiple]').each(function() {
      $('option').mousedown(function(e) {
        e.preventDefault();
        var originalScrollTop = $(this).parent().scrollTop();
        $(this).prop('selected', $(this).prop('selected') ? false : true);
        var self = this;
        $(this).parent().focus();
        setTimeout(function() {
            $(self).parent().scrollTop(originalScrollTop);
        }, 0);
        return false;
      });
    })
  }
}
